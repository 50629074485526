import React,{useEffect,useState} from 'react';
import { fetchWrapper } from '../../../helpers';
import Create from './Create'
import CreateBudget from './Create-Budget'

import CustomModalLink from '../../../components/CustomModalLink'
import CustomModal from '../../../components/CustomModal'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

function List() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [list,setList] = useState([])

	const [Name,setName] = useState('')

	useEffect(() => {
				const url_id = window.location.href.split('/');
        const id = url_id[url_id.length - 1];

        loadList(id)
    }, []);

  	const loadList = async (id) => {
      const url = `${process.env.REACT_APP_API_URL}/api/sow-list?project_id=${id}`;
      const data  = await fetchWrapper.get(url)
      // console.log(data)
      setList(data.rows)
  	}

  	const meClose = () => {
  		
		   onClose();
		   
	}

	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const meEdit = (veiw , rs) => {
		const Name = rs.name
			setName(Name)
		if(veiw === 'Edit'){
			setIsOpenEdit(true);
			setModalEditView(<Create rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)
		}
		if(veiw === 'mb'){
			setIsOpenEdit(true);
			setModalEditView(<CreateBudget rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)
		}
				
	}


	const activeList = list.filter((item) => item.status_id === 1);
	const completedList = list.filter((item) => item.status_id === 10);
	const holdList = list.filter((item) => item.status_id === 2);

	return(
		<>
		
			<CustomModalLink
        showModalButtonText="Add Budget"
        modalHeader={Name}
     		isOpenEdit={isOpenEdit}
     		onCloseEdit={meCloseEdit}
     		onOpenEdit={onOpenEdit}
        modalBody={modalEditView}
    	/>
		<HStack justifyContent="space-between">
			<Box>
				<Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Montserrat', sans-serif">Active</Text>
			</Box>

				
		</HStack>
		<div className="table_section">
			<TableContainer width="100%">
		  		<Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
				    <Thead>
					      <Tr>
					        <Th>SOW Name</Th>
				         	<Th>Start Date</Th>
			          	<Th>End Date</Th>
			          	<Th>No of Resources</Th>
					        <Th>Total SOW Hours</Th>
					        <Th>Total budgets</Th>
					        <Th>Monthly budgets</Th>
					        <Th>Status</Th>
					       
					        <Th></Th>
					      </Tr>
				    </Thead>
				    <Tbody>	
					    {
					    	activeList.length === 0 ? (
    <Tr>
      <Td colSpan="7" textAlign="center">
        No data found
      </Td>
    </Tr>
  ) : (

					    	activeList.map((rs,index)=>{
		            	return(
		                   <Tr key={index}>
			                    <Td style={{color:"#3B86FF"}}>{rs.name}</Td>
			                    <Td>{rs.start_date}</Td>
			                    <Td>{rs.end_date}</Td>
			                   	<Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.no_of_resources, 0)}</Td>
			                    <Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.total_hours, 0)}</Td>
			                    <Td>{rs.total_budget ? parseInt(rs.total_budget).toLocaleString() : ''}</Td>
			                    <Td>{rs.monthly_budget ? parseInt(rs.monthly_budget).toLocaleString() : ''}</Td>
			                    <Td>InProcess</Td>                   
			                    <Td style={{color:"#0085FF"}}>
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                <MenuItem  h="16px" fontSize="12px" onClick={() => meEdit('Edit',rs)}>{rs.total_budget === null || rs.total_budget === '' ? 'Add Budget' : 'Edit Budget'}</MenuItem>
                                <MenuDivider />
                                {rs.total_budget ? 
                                	<MenuItem h="16px" fontSize="12px" onClick={() => meEdit('mb',rs)} >Monthly Budget</MenuItem>
                                : 
                                <MenuItem style={{ color: 'red' }} h="16px" fontSize="12px">No Budget</MenuItem>
                              	}
                                
                                
                              </MenuList>
                            </Menu>
                            </Td>
		                    </Tr>
		              	)
		          		}))}				        
				    </Tbody> 
			  	</Table>
			</TableContainer>
				</div>

		<Box mb="20px" mt="20px">
			<HStack justifyContent="space-between">
				<Box>
					<Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Montserrat', sans-serif">Hold</Text>
				</Box>

				
			</HStack>
			<div className="table_section">
			<TableContainer width="100%">
		  		<Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
				    <Thead>
					      <Tr>
					        <Th>SOW Name</Th>
				         	<Th>Start Date</Th>
			          	<Th>End Date</Th>
			          	<Th>No of Resources</Th>
					        <Th>Total SOW Hours</Th>
					        <Th>Total budgets</Th>
					        <Th>Monthly budgets</Th>
					        <Th>Status</Th>
					       
					        <Th></Th>
					      </Tr>
				    </Thead>
				    <Tbody>	
					    {
					    	holdList.length === 0 ? (
						    <Tr>
						      <Td colSpan="7" textAlign="center">
						        No data found
						      </Td>
						    </Tr>
						  ) : (

					    	holdList.map((rs,index)=>{
		            	return(
		                   <Tr key={index}>
			                    <Td style={{color:"#3B86FF"}}>{rs.name}</Td>
			                    <Td>{rs.start_date}</Td>
			                    <Td>{rs.end_date}</Td>
			                   	<Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.no_of_resources, 0)}</Td>
			                    <Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.total_hours, 0)}</Td>
			                    <Td>{rs.total_budget ? parseInt(rs.total_budget).toLocaleString() : ''}</Td>
			                    <Td>{rs.monthly_budget ? parseInt(rs.monthly_budget).toLocaleString() : ''}</Td>
			                    <Td>{rs.status_id === 2 ? 'Hold' :"Completed" }</Td>          
			                    <Td style={{color:"#0085FF"}}>
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                <MenuItem  h="16px" fontSize="12px" onClick={() => meEdit('Edit',rs)}>{rs.total_budget === null || rs.total_budget === '' ? 'Add Budget' : 'Edit Budget'}</MenuItem>
                                <MenuDivider />
                                {rs.total_budget ? 
                                	<MenuItem h="16px" fontSize="12px" onClick={() => meEdit('mb',rs)} >Monthly Budget</MenuItem>
                                : 
                                <MenuItem style={{ color: 'red' }} h="16px" fontSize="12px">No Budget</MenuItem>
                              	}
                                
                                
                              </MenuList>
                            </Menu>
                            </Td>
		                    </Tr>
		              	)
		          		}))}				        
				    </Tbody> 
			  	</Table>
			</TableContainer>
				</div>
		</Box>		

		<HStack justifyContent="space-between">
			<Box>
				<Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Montserrat', sans-serif">Compeleted</Text>
			</Box>

				
		</HStack>
		<div className="table_section">
			<TableContainer width="100%">
		  		<Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
				    <Thead>
					      <Tr>
					        <Th>SOW Name</Th>
				         	<Th>Start Date</Th>
			          	<Th>End Date</Th>
			          	<Th>No of Resources</Th>
					        <Th>Total SOW Hours</Th>
					        <Th>Total budgets</Th>
					        <Th>Monthly budgets</Th>
					        <Th>Status</Th>
					       
					        <Th></Th>
					      </Tr>
				    </Thead>
				    <Tbody>	
					    {
					    	completedList.length === 0 ? (
    <Tr>
      <Td colSpan="7" textAlign="center">
        No data found
      </Td>
    </Tr>
  ) : (
					    	completedList.map((rs,index)=>{
		            	return(
		                   <Tr key={index}>
			                    <Td style={{color:"#3B86FF"}}>{rs.name}</Td>
			                    <Td>{rs.start_date}</Td>
			                    <Td>{rs.end_date}</Td>
			                   	<Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.no_of_resources, 0)}</Td>
			                    <Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.total_hours, 0)}</Td>
			                    <Td>{rs.total_budget ? parseInt(rs.total_budget).toLocaleString() : ''}</Td>
			                    <Td>{rs.monthly_budget ? parseInt(rs.monthly_budget).toLocaleString() : ''}</Td>
			                   	<Td>{rs.status_id === 10 ? 'Completed' :"Hold" }</Td>                  
			                    <Td style={{color:"#0085FF"}}>
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                <MenuItem  h="16px" fontSize="12px" onClick={() => meEdit('Edit',rs)}>{rs.total_budget === null || rs.total_budget === '' ? 'Add Budget' : 'Edit Budget'}</MenuItem>
                                <MenuDivider />
                                {rs.total_budget ? 
                                	<MenuItem h="16px" fontSize="12px" onClick={() => meEdit('mb',rs)} >Monthly Budget</MenuItem>
                                : 
                                <MenuItem style={{ color: 'red' }} h="16px" fontSize="12px">No Budget</MenuItem>
                              	}
                                
                                
                              </MenuList>
                            </Menu>
                            </Td>
		                    </Tr>
		              	)
		          		}))}				        
				    </Tbody> 
			  	</Table>
			</TableContainer>
				</div>				
		</>
	)
}

export default List;