import React, { useEffect, useState } from 'react';
import { HStack, Box, Image } from '@chakra-ui/react';
import Headerlogo from '../images/header_logo.png';
import { Link ,useNavigate,useLocation } from "react-router-dom";
import {
  Flex,
  Text,
  Avatar,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';
import "../index.css";
import secureStorage from "react-secure-storage";
function Header(){
	const [email, setEmail] = useState('');
	const [userData, setUserData] = useState('');
	useEffect(() => {
	    // Check if the token is present in local storage
	    const token = localStorage.getItem('user');


	    if (token) {
	      const decodedToken = decodeToken(token);
	      const Userdata = decodedToken.Userdata;
	    	setUserData(Userdata)

	      // Set the email state
	      setEmail(Userdata.email);
	    }
	  }, []);

	const decodeToken = (token) => {
	    const decodedString = atob(token.split('.')[1]);
	    const decodedObject = JSON.parse(decodedString);
	    // console.log(decodedObject)
	    return decodedObject;
	  };

	const navigate = useNavigate();
	const location = useLocation()

	const meLogout = ()=>{
		localStorage.removeItem('user')
		secureStorage.removeItem('userId')
		navigate("/")
	}
	// const user_id = secureStorage.getItem("userId");
	return(
		<>
			<HStack h="70px" bg="#31367A" py="10px" px="50px" justifyContent="space-between">
				<Box>
					<HStack>
						<Box>
							<Image src={Headerlogo} alt='Hive' />
						</Box>

						<Box>
							
						</Box>
					</HStack>
				</Box>

				<Box>
					<Menu>
		              <MenuButton
		                as={Button}
		                rounded={'full'}
		                variant={'link'}
		                cursor={'pointer'}
		                minW={0}>
		                <HStack>
		                  {/*<Box>
		                    <Avatar
		                      size={'sm'}
		                      src={
		                        'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
		                      }
		                    />
		                  </Box>*/}
		                	<Box
                            width="35px"
                            height="35px"
                            bg="#ccc"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="50%"

                          >
                            <Text fontWeight="400" fontSize="14px" color="#000">
                              {email?.charAt(0).toUpperCase()}
                            </Text>
                          </Box>

		                  <Box>
		                    <Text fontFamily="Open Sans, sans-serif" fontSize="12px" fontWeight="400" color="#fff" textAlign="left">Welcome</Text>
		                    <Text fontFamily="Open Sans, sans-serif" fontSize="12px" fontWeight="400" color="#fff" textAlign="left">{email}</Text>
		                  </Box>
		                </HStack>
		                
		              </MenuButton>
		              <MenuList onClick={()=> meLogout()}>
		                <MenuItem>Logout</MenuItem>
		              </MenuList>
		            </Menu>
				</Box>
			</HStack>

			<HStack px="50px" h="50px" borderColor="#ccc" borderBottomWidth="1px" borderStyle="solid">
				<Box me="30px">
					<Link className={`header_link ${location.pathname === "/dashboard" ? "active" : ""}`} to="/dashboard">Dashboard</Link>
				</Box>


				{userData.role_id == 1 && (
				<>
					<Box me="30px">
						<Link className={`header_link ${location.pathname === "/team" ? "active" : ""}`} to="/team">My Team</Link>
					</Box>
					<Box me="30px">
						<Link className={`header_link ${location.pathname === `/employee` ? "active" : ""}`} to={`/employee`}>Employees</Link>
					</Box>
				</>
				)
				}
				

				<Box me="30px">
					<Link className={`header_link ${location.pathname === `/my-time` ? "active" : ""}`} to={`/my-time`}>My TimeSheet</Link>
				</Box>

				<Box me="30px">
					<Link  className={`header_link ${location.pathname === "/project" ? "active" : ""}`}  to="/project" >Projects</Link>
				</Box>

				<Box me="30px">
					<Link className="header_link">Talent Requisition</Link>
				</Box>

				<Box me="30px">
					<Link className="header_link">HR</Link>
				</Box>

				<Box me="30px">
					<Link className={`header_link ${location.pathname === "/Finance" ? "active" : ""}`}>Finance</Link>
				</Box>

				<Box me="30px">
					<Link className={`header_link ${location.pathname === "/Reports" ? "active" : ""}`}>Reports</Link>
				</Box>
				{userData.role_id == 1 &&
				<>
				<Box>
					<Link className={`header_link ${location.pathname === "/settings" ? "active" : ""}`}   to="/settings">Settings</Link>
				</Box>
				</>
				}
			</HStack>
		</>
	);
}

export default Header;