import React from 'react';
import Header from '../components/Header';
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading
} from '@chakra-ui/react'
import "../index.css";
import { Link } from "react-router-dom";
import List from './project/List'

function Dashboard(){
	return(
		<>
			
			<Header />
			<div className="page_section">
				
				<Box  py="20px">
					<Heading fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000">Dashboard</Heading>
				</Box>
			</div>

			
			
		</>
	)
}

export default Dashboard;