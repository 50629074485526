import React from 'react';
import {
  
  TextareaControl,
  
} from "formik-chakra-ui";

function TextAreaField(props) {
	const {name,type,inputValue,title,placeholder} = props
	const [value, setValue] = React.useState('')
	const handleChange = (event) => setValue(event.target.value)
	return(
		<>
			<div className="field_section">

				  <TextareaControl name={name} label={title}  textareaProps={{ placeholder: placeholder }} />
				
			</div>
			
		</>
	);
}

export default TextAreaField;