import React,{useState,useEffect} from "react";
import CustomModalLink from '../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  Checkbox,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Heading,
} from '@chakra-ui/react'
import { fetchWrapper } from '../../helpers';
import EditTime from './EditTime'

function Popup({ rs ,emp_id,setShows}) {
  // console.log(rs)

    const [userData, setUserData] = useState('');
  useEffect(() => {
      // Check if the token is present in local storage
      const token = localStorage.getItem('user');


      if (token) {
        const decodedToken = decodeToken(token);
        const Userdata = decodedToken.Userdata;
        setUserData(Userdata)

      }
    }, []);

  const decodeToken = (token) => {
      const decodedString = atob(token.split('.')[1]);
      const decodedObject = JSON.parse(decodedString);
      // console.log(decodedObject)
      return decodedObject;
    };
   const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)
  const [show,setShow] = useState(false)



  const [selectedTasks, setSelectedTasks] = useState([]); 
  const [selectAll, setSelectAll] = useState(false);
  const [Data, setData] = useState([]); 

  // const Data = rs.Data;

  useEffect(()=>{
    loadList(rs);
  },[show])

  const loadList = async(rs)=>{
    const data = {
      task_date : rs.start,
      employee_id:emp_id
    }
    const url = `${process.env.REACT_APP_API_URL}/api/timesheet-reviewed`;
    const list = await fetchWrapper.post(url,data)
    setData(list.rows)
  }


  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    }).format(date);
  };


  let previousDate = null;


   const handleTaskSelect = (id) => {
    if (selectedTasks.includes(id)) {
      setSelectedTasks(selectedTasks.filter(taskId => taskId !== id));
    } else {
      setSelectedTasks([...selectedTasks, id]);
    }
  };

  
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTasks([]); 
    } else {
      const allTaskIds = Data.map((task) => task.id); 
      setSelectedTasks(allTaskIds);
    }
    setSelectAll(!selectAll);
  };


  const handleReviewed = async() => {
    const reviewedTasks = Data.filter(task => selectedTasks.includes(task.id));
    if(reviewedTasks.length == 0){
      alert('At least on select');
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/api/timesheet-approved`;
    await fetchWrapper.post(url,reviewedTasks);
    setSelectAll(false);
    setSelectedTasks([]);
    onToggle()
  };
  const meCloseEdit = () => {
      
       setIsOpenEdit(false);
       setShows(!show)
  }

  const onToggle = ()=>{
    setShow(!show)
  }

  const meTime = async(rs,type)=>{
    // console.log(rs)
    if(type === 'edit'){
      setModalEditView(<EditTime meCloseEdit={meCloseEdit} rs={rs} onToggle={onToggle} />)
      setIsOpenEdit(true);
    }
    if(type === "delete"){
      const aa = window.confirm('Are you Sure');
      if(aa){
        const url = `${process.env.REACT_APP_API_URL}/api/timesheet-emp-delete-by-id/${rs.id}`;
        await fetchWrapper.delete(url);
        onToggle();
      }
    }
  }

  return (
    <>
     <CustomModalLink
        showModalButtonText="Edit Time"
        modalHeader="Edit Time"
        isOpenEdit={isOpenEdit}
        onCloseEdit={meCloseEdit}
        onOpenEdit={onOpenEdit}
        
        modalBody={modalEditView}
      />

      <HStack justifyContent="space-between">
        <Box>
          <Checkbox isChecked={selectAll} onChange={handleSelectAll}>Select All</Checkbox>
        </Box>
        {userData.role_id == 1 && 
          <>
          <Box>
            <Button colorScheme="blue" borderRadius="4px" w="91px" h="32px" onClick={handleReviewed}>
              Reviewed
            </Button>
          </Box>
          </>
        }
      </HStack>

    



      
        {Data.map((rs)=>{
          const currentDate = new Date(rs.task_date);
          const shouldDisplayDate = !previousDate || previousDate.getTime() !== currentDate.getTime();
          previousDate = currentDate;

          return(
            <>
               <Box mt="10px">
                  {shouldDisplayDate && (
                    <Heading fontSize="19px">{formatDate(currentDate)}</Heading>
                  )}

                  <HStack alignItems="baseline">
                    <Box>
                      <Checkbox isChecked={selectedTasks.includes(rs.id)} onChange={() => handleTaskSelect(rs.id)} style={{ top: "5px" }}></Checkbox>
                    </Box>

                    <Box w="90%">
                      <HStack justifyContent="space-between" w="91%">
                        <Button className="Design-Label">{rs?.Task?.name}</Button>
                        <Box>
                          <HStack>
                          {rs.approved_by === 1 ? (
                            <> 
                            <Box>
                              <Button className="Reviewed-Label">Reviewed</Button>
                            </Box>

                            </>
                          ) :(
                          <>
                           <Box>
                              <Button className="not-Reviewed-Label">not Reviewed</Button>
                            </Box>

                          </>

                          )

                          }
                           
                          </HStack>
                        </Box>
                      </HStack>
                      <HStack alignItems="baseline" justifyContent="space-between">
                        <Box w="95%">
                          <Text fontSize="14px" fontWeight="400" mb="0px">
                            {rs.task_description}
                          </Text>
                        </Box>
                      </HStack>
                      <Text>
                        {" "}
                        <span onClick={()=> meTime(rs,'edit')}>Edit</span> | <span onClick={()=> meTime(rs,'delete')}>Delete</span>
                      </Text>
                    </Box>
                    <Box>
                      <Text mb="0px" fontSize="19px" fontWeight="600">
                       {(() => {
                          if (rs.task_time) {
                            const [hours, minutes] = rs.task_time.split(':');
                            
                            if (parseInt(hours) > 0) {
                              return 'Hours';
                            }
                            
                            if (parseInt(minutes) > 0) {
                              return 'Minutes';
                            }
                          }
                          return '';
                        })()}
                      </Text>
                      <Text fontSize="24px" fontWeight="800">
                       {rs.task_time}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
            </>
          )
        })}
      
    </>
  );
}
export default Popup;
