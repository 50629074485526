import React ,{useState,useEffect	}from 'react'
import DatePicker from "react-datepicker";
import Header from '../../components/Header';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Progress,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  useDisclosure,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  MenuDivider,
  Input
} from '@chakra-ui/react'
import { BsArrowLeftShort } from "react-icons/bs";
import {useLocation,useNavigation } from "react-router-dom";
import emp_user from '../../images/Ellipse 9.png'
import { MdArrowDropDown } from "react-icons/md";
import { fetchWrapper } from '../../helpers';
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Reviewed from "./Reviewed";
import CustomModalLink from '../../components/CustomModalLink'
import AddTime from "./AddTime";
const localizer = momentLocalizer(moment);

function Veiw() {
  const [searchEmp, setSearchEmp] = useState("");
  const [selectedEmp, setSelectedEmp] = useState('');
  const [show, setShow] = useState(false);
 const [monthHour, setMonthHour] = useState({ total_hour: '00', total_mint: '00' });
  const customModalStyles = {
    width: '791px',
    right: '0px',
    left: 'unset',
    justifyContent: 'right',
		height: '100vh',
    marginTop: '0px',
    marginBottom: '0px',
  };

  const customHeaderStyles = {
    backgroundColor: '#f0f8ff',
    fontSize: '19px',
    fontWeight: '600',
    paddingRight: '30px',
    lineHeight: '22.67px',
  };

  const customOverlayStyles = {
    background: 'rgba(0, 0, 0, 0.8)',
    opacity: 'unset !important',
  };

		const url_id = window.location.href.split('/');
   	const id = url_id[url_id.length - 1];


   
  const [calendarHeight, setCalendarHeight] = useState(790);
  const [events, setEvents] = useState([]);
  const [modelHeader, setmodelHeader] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [modalEditView, setModalEditView] = useState(false);


	const [selectedMonth, setSelectedMonth] = useState(new Date());
	const [list,setList] = useState([])
  const [employee,setEmployeeList] = useState([])
	const [timeData,setTimeData] = useState([])

  const [groupTasks,setGroupTasks] = useState([])

  const handleMonthChange = (date) => {
  	setSelectedMonth(date);
  };

  const meCloseEdit = () => {
    setIsOpenEdit(false);
    setModalEditView(null);
    setShow(!show)
  };

  useEffect(() => {
        loadList(selectedEmp.id);
        loadTimeSheetList(selectedEmp.id);
        loadEmployeeList();
  }, [selectedEmp.id,show]);

  const loadEmployeeList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/cmd/all-employee`;
      const data  = await fetchWrapper.get(url)
      setEmployeeList(data.rows)
    }

	const loadList = async (emp_id) => {
    const Uid = emp_id ? emp_id : id
    const url = `${process.env.REACT_APP_API_URL}/api/cmd/employee/${Uid}`;
    const data  = await fetchWrapper.get(url)
    setList(data)
    // console.log(data)
	}

	const loadTimeSheetList = async (emp_id) => {
    const Uid = emp_id ? emp_id : id
    const url = `${process.env.REACT_APP_API_URL}/api/timesheet-emp?emp_id=${Uid}`;
    const data  = await fetchWrapper.get(url)
    setTimeData(data.rows)
    processTasks(data.rows)
    // console.log(data)
	}

  const processTasks = (timeData) => {
    const groupedTasks = timeData.reduce((acc, rs) => {
      const month = selectedMonth.getMonth() + 1;
      const monthofTask = new Date(rs.task_date).getMonth()+1;
      const taskName = rs.Task?.name || ''; // Get task name
      const taskTime = rs.task_time || '00:00'; // Get the time spent on the task (assumed to be in HH:mm format)

      // Convert time string 'HH:mm' to total minutes
      const [hours, minutes] = taskTime.split(':').map(num => parseInt(num, 10));
      const totalMinutes = hours * 60 + minutes;

      // Compare with selected month
      if (monthofTask !== month) {
          return acc; // Skip if task is not from the selected month
      }
      // If task exists, sum the time; otherwise, create new task entry
      if (acc[taskName]) {
        acc[taskName].totalMinutes += totalMinutes; // Add to the existing total minutes
      } else {
        acc[taskName] = {
          name: taskName,
          totalMinutes: totalMinutes, // Initialize the total minutes for this task
          color:''
        };
      }

      return acc;
    }, {});

    // Function to generate a color based on task name (deterministic)
    const generateColorFromName = (name) => {
      // Simple hash function to convert name to a number
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = (hash << 5) - hash + name.charCodeAt(i);
        hash |= 0; // Convert to 32-bit integer
      }
      // Generate a hex color code from the hash
      const color = (hash & 0x00FFFFFF).toString(16).toUpperCase();
      return `#${'000000'.substring(0, 6 - color.length) + color}`;
    };

    // Convert total minutes back to 'HH:mm' format for each task
    const taskList = Object.values(groupedTasks).map(task => {
      if (!task.color) {
        task.color = generateColorFromName(task.name);
      }
      const totalMinutes = task.totalMinutes;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      // Format to 'HH:mm' format
      const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

      return {
        name: task.name,
        hours: formattedTime, // The summed time in HH:mm format
        color:task.color
      };
    });

    // console.log(taskList); // Optional: For debugging
    setGroupTasks(taskList); // Save to the state (if needed)
  };  


	const meAddTime = ()=>{
		setIsOpenEdit(true);
		setmodelHeader('Add Time')
    setModalEditView(<AddTime meCloseEdit={meCloseEdit} loadList={loadTimeSheetList} emp_id={selectedEmp.id ? selectedEmp.id : id} />);
	}


  	useEffect(() => {
    // Generate events for the selected month including previous and next month's days shown in the current month's view
    const startOfMonth = moment(selectedMonth).startOf("month");
    const endOfMonth = moment(selectedMonth).endOf("month");
    const startOfCalendarView = startOfMonth.clone().startOf("week");
    const endOfCalendarView = endOfMonth.clone().endOf("week");
    const numberOfWeeks = Math.ceil(
      (endOfMonth.diff(startOfMonth, "days") + startOfMonth.day() + 1) / 7
    );

    // Set the height based on the number of weeks
    if (numberOfWeeks === 5) {
      setCalendarHeight(661);
    } else {
      setCalendarHeight(790);
    }
    const daysInView = endOfCalendarView.diff(startOfCalendarView, "days") + 1;

    const generatedEvents = [];

  
for (let i = 0; i < daysInView; i++) {
    const date = moment(startOfCalendarView).add(i, "days");
    const formattedDate = date.format("YYYY-MM-DD");

    // Filter events for the current date
    const existingEvents = timeData.filter((rs) =>
        moment(rs.task_date).isSame(formattedDate, "day")
    );

    // Function to sum task times from existing events
    const sumTaskTimes = (events) => {
        return events.reduce((accumulator, event) => {
            const taskTime = event.task_time;

            if (taskTime) {
                const timeMatch = taskTime.match(/^(\d{1,2}):(\d{2})$/);
                if (timeMatch) {
                    const hours = parseInt(timeMatch[1], 10) || 0; 
                    const minutes = parseInt(timeMatch[2], 10) || 0; 

                    accumulator.hours += hours;
                    accumulator.minutes += minutes;
                }
            }
            return accumulator;
        }, { hours: 0, minutes: 0 }); 
    };

    // Sum the task times for the day
    const { hours: totalHours, minutes: totalMinutes } = sumTaskTimes(existingEvents);

    // Convert total minutes to hours and minutes
    const finalHours = totalHours + Math.floor(totalMinutes / 60);
    const finalMinutes = totalMinutes % 60;


    const calculated = existingEvents.reduce((total, event) => {
    const [hours, minutes] = event.task_time.split(':').map(Number);
    return total + (hours * 60) + minutes;
  }, 0);

  // Convert back to hours and minutes
  const calculateTime = Math.floor(calculated / 60);
  const remainingMinutes = calculated % 60;

  const totalTime = `${String(calculateTime).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;

    // Push data to the generated events array
    generatedEvents.push(existingEvents.length > 0 ? {
        time: totalTime, 
        Hours: `${finalHours} Hours ${finalMinutes} Mins`, 
        start: date.format("YYYY-MM-DD"),
        end: date.format("YYYY-MM-DD"),
        label: "",
    } : {
        time: "00",
        Hours: "Hours",
        start: date.format("YYYY-MM-DD"),
        end: date.format("YYYY-MM-DD"),
        label: "",
    });
}


setEvents(generatedEvents);
processTasks(timeData)

  }, [selectedMonth,timeData]);




  // Sample events data
  // const sampleEvents = [
  //   {
  //     time: "08",
  //     Hours: "Hours",
  //     start: new Date("2024-05-10T10:00:00"),
  //     end: new Date("2024-05-10T12:00:00"),
  //     label: "holiday",
  //   },
  //   {
  //     time: "08",
  //     Hours: "Hours",
  //     start: new Date("2024-02-10T10:00:00"),
  //     end: new Date("2024-02-10T12:00:00"),
  //     label: "holiday",
  //   },
  //   {
  //     time: "03",
  //     Hours: "Hours",
  //     start: new Date("2024-05-15T14:00:00"),
  //     end: new Date("2024-05-15T16:00:00"),
  //     label: "On Leave",
  //   },
  //   {
  //     time: "12",
  //     Hours: "",
  //     start: new Date("2024-05-31T14:00:00"),
  //     end: new Date("2024-05-31T14:00:00"),
  //     label: "Day Off",
  //   },
  //   {
  //     time: "20",
  //     Hours: "",
  //     start: new Date(),
  //     end: new Date(),
  //     label: "Day Off",
  //   },
  //   {
  //     time: "25",
  //     Hours: "",
  //     start: new Date("2024-06-05T14:00:00"),
  //     end: new Date("2024-06-05T14:00:00"),
  //     label: "Day Off",
  //   },
  // ];

  const customToolbar = () => {
    return null; 
  };

  const dayPropGetter = (date) => {
    // const dayOfWeek = date.getDay();
    // const isHoliday = dayOfWeek === 0 || dayOfWeek === 6;
    // if (isHoliday) {
      
    //   return {
    //     className: "holiday-off",
    //   };
    // }
    // return {};
  };

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.color || "blue", 
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
      padding: "2px 10px",
    };

    return {
      style: style,
    };
  };

  const formatDate = (date) => {
	  return new Intl.DateTimeFormat('en-US', {
	    weekday: 'long', 
	    year: 'numeric', 
	    month: 'long', 
	    day: 'numeric' 
	  }).format(date);
	};


  let isDebouncing = false;

const meOpen = (event, timeData) => {
    if (isOpenEdit || isDebouncing) {
        return;
    }

    isDebouncing = true;
    setTimeout(() => { isDebouncing = false; }, 300);

    // Set the modal to open
    setIsOpenEdit(true);
    
    const startDate = new Date(event.start);
    const formattedDate = formatDate(startDate);
    setmodelHeader(formattedDate);

    // const matchingTimeData = timeData.filter(data => {
    //     const dataDate = new Date(data.task_date);
    //     return dataDate.getTime() === startDate.getTime();
    // });

    const rs = {
        ...event,
        // Data: matchingTimeData
    };

    setModalEditView(<Reviewed setShows={setShow} meCloseEdit={meCloseEdit} rs={rs} emp_id={selectedEmp.id ? selectedEmp.id : id} />);
};

  const EventComponent = ({ event }) => {
    const eventDate = new Date(event.start);
    const dayOfWeek = eventDate.getDay();

    // if (dayOfWeek === 0 || dayOfWeek === 6) {
    //   return null;
    // }

    const today = new Date();
    const isToday = moment(eventDate).isSame(today, "day");

    if (!isToday && eventDate > today) {
      return (
        <div className="box-section not_click">
          <div className="time">00</div>
          <div className="hours">Hours</div>
          <div className=""></div>
        </div>
      );
    }


   

    return (
      <div className="box-section" onClick={() => meOpen(event,timeData)} >
         {event.label !== "Day Off" ? (
				    <>
				      <div className="time">{event.time || "00"}</div>
				      <div className="hours">{event.Hours || "Hours"}</div>
				    </>
				  ) : (
				  <>
				    <div className="time"></div>
				    <div className="hours"></div>
					</>
				  )}
        {event.label && (
          <div className={event.label === "holiday" ? "holiday" : "onleave"} style={event.label === "Day Off" ? { marginTop: '60px' } : {}} >
            {event.label}
          </div>
        )}
      </div>
    );
  };

  const calculateTotalHours = (events, month) => {
    const totalHoursPerWeek = {};

    events.forEach((event) => {
      const eventStart = moment(event.start);
      const dayOfWeek = eventStart.day();
      
      // // Skip weekends
      // if (dayOfWeek === 0 || dayOfWeek === 6) {
      //   return; 
      // }

      // Calculate start of the week
      const startOfWeek = eventStart.startOf("week").format("YYYY-MM-DD");
      const timeParts = event.time.split(':'); 
      const hours = parseInt(timeParts[0], 10) || 0; 
      const minutes = parseInt(timeParts[1], 10) || 0; 

      const totalMinutes = hours * 60 + minutes;

      // Aggregate total minutes for each week
      if (totalHoursPerWeek[startOfWeek]) {
        totalHoursPerWeek[startOfWeek] += totalMinutes;
      } else {
        totalHoursPerWeek[startOfWeek] = totalMinutes;
      }
    });

 
    const formattedTotalHoursPerWeek = {};
    for (const [week, totalMinutes] of Object.entries(totalHoursPerWeek)) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      formattedTotalHoursPerWeek[week] = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`; 
    }

    return formattedTotalHoursPerWeek;
  };

const month = selectedMonth.getMonth();
const totalHoursPerWeek = calculateTotalHours(events, month);

useEffect(() => {
  
  const TotalMonth = events.reduce((sum, event) => {
    const eventStart = moment(event.start);
    
    if (eventStart.month() === month) {
      const timeParts = event.time.split(':');
      const hours = parseInt(timeParts[0], 10) || 0; 
      const minutes = parseInt(timeParts[1], 10) || 0; 
      return sum + (hours * 60 + minutes);
    }
    return sum;
  }, 0);

  
  const totalHours = Math.floor(TotalMonth / 60);
  const remainingMinutes = TotalMonth % 60;

  
  setMonthHour({
    total_hour: totalHours < 10 ? `0${totalHours}` : totalHours,
    total_mint: remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes,
  });
}, [ month, events]);




  const TotalHoursComponent = () => {
    const weeks = Object.keys(totalHoursPerWeek).map((weekStart) => (
      <div key={weekStart} className="box-section total-hours-cell">
        <div className="time">{totalHoursPerWeek[weekStart]}</div>
        <div className="hours">Hours</div>
      </div>
    ));

    return <div className="total-hours-container">{weeks}</div>;
  };

  const meSearch = (e)=>{

    
      setSearchEmp(e.target.value)
    
  }

  const handleSelect = (rs) => {
      const { id, name } = rs;
    // console.log(rs)
      setSelectedEmp({ id, name });
   
  };

  const filteredEmployee = employee?.filter((rs) =>
    rs.name.toLowerCase().includes(searchEmp.toLowerCase())
  );



  const totalHoursInMinutes = (parseInt(monthHour.total_hour) * 60) + parseInt(monthHour.total_mint); // Convert to total minutes

  // Calculate total progress and gradient segments dynamically
  let totalProgress = 0;
  const gradients = groupTasks.map((task) => {
   const taskHoursInMinutes = (parseInt(task.hours.split(':')[0]) * 60) + parseInt(task.hours.split(':')[1]);
  
    const progress = (taskHoursInMinutes / totalHoursInMinutes) * 100;
    totalProgress += progress;

    return {
      color: task.color,
      percentage: progress,
    };
  });

  // Create the linear gradient using the calculated task segments
  const gradient = gradients.length > 0
  ? gradients
      .map((grad, idx) => {
        const startPercent = idx === 0 ? 0 : gradients.slice(0, idx).reduce((sum, g) => sum + g.percentage, 0);
        const endPercent = startPercent + grad.percentage;
        return `${grad.color} ${startPercent}% ${endPercent}%`;
      })
      .join(', ')
  : 'teal 0% 100%';





	return(
		<>

		 <CustomModalLink
        showModalButtonText={modelHeader}
        modalHeader={modelHeader}
        isOpenEdit={isOpenEdit}
        onCloseEdit={meCloseEdit}
        onOpenEdit={onOpenEdit}
        modalBody={modalEditView}
        className="side_popup"
        customModalStyles={customModalStyles}
         customHeaderStyles={customHeaderStyles}
          customOverlayStyles={customOverlayStyles}
      />
		<Header/>
		<div className="Team_Veiw">

			<HStack cursor="pointer" >
 				<Box className="Backedicon" onClick={()=> window.location.href="/team"}>
					<BsArrowLeftShort />
				</Box>
				<Box className="Backed">
					<Text>TimeSheets</Text>
				</Box>
	 		</HStack>


	 		<Box mt="40px">
	 			<HStack justifyContent="space-between" >
	 					<Box cursor="pointer">
				            <HStack border="1px solid #ccc" px="10px" py="10px" borderRadius="7px">
				               {/*<Box>
				                    <Image src={emp_user} alt="Employee User" />
				                </Box>*/}
                        <Box
                            width="70px"
                            height="70px"
                            bg="#ccc"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="50%"

                          >
                            <Text fontWeight="400" fontSize="24px" color="#000">
                              {selectedEmp?.name ? selectedEmp.name?.charAt(0).toUpperCase() : list.name?.charAt(0).toUpperCase()}
                            </Text>
                          </Box>
				                <Box marginLeft="10px">
				                    
				                        
                                 <Menu>
                                    <MenuButton>
                                      <HStack>
                                        <Box>
                                        <Text fontWeight="400" fontSize="24px" color="#000000">
                                          {selectedEmp.name || list.name} 
                                        </Text>
                                        </Box>
                                        <Box display="inline-block" verticalAlign="middle"><MdArrowDropDown /></Box>
                                      </HStack>
                                    
                                    </MenuButton>
                                    <MenuList>
                                     <Box p="10px">
                                      <Input

                                        placeholder="Search Employee"
                                        value={searchEmp}
                                        onChange={(e) => meSearch(e)}
                                      />
                                      </Box>
                                    {filteredEmployee.slice(0,10).map((rs)=>{
                                      return(
                                      <>
                                        <MenuItem  onClick={() => handleSelect(rs)}>{rs.name}</MenuItem>
                                      </>
                                      )
                                    })}

                                      
                                      
                                    </MenuList>
                                  </Menu>
				                    
				                    
				                    <Text fontWeight="400" fontSize="12px" color="#00000080">
				                       {list.designation}
				                    </Text>
				                </Box>
				            </HStack>
				        </Box>
	 					
	 					<Box>
	 						<HStack alignItems="center">
	 								<Box>
										 <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif" borderRadius="4px"
								           >
						          			Review Time sheet
						          		</Button>
									</Box>

									<Box>
										 <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif" borderRadius="4px"
								           onClick={() => meAddTime()}>
						          		Add Time
						          		</Button>
									</Box>



	 								<Box>
					          <DatePicker

					            className="MonthPicker"
					            selected={selectedMonth}
					            onChange={handleMonthChange}
					            dateFormat="MMM yyyy"
					            showMonthYearPicker
					            onKeyDown={(e) => e.preventDefault()}
					            showIcon
					            toggleCalendarOnIconClick
					          />
					        </Box>
	 						</HStack>
	 					</Box>
	 			</HStack>

	 		</Box>


	 		<Box className="Box_Section" mt="20px">
	 			<HStack w="100%" justifyContent="space-between" alignItems="start">
	 				<HStack className="CustomCalender" gap="0" w="67%">
          <Box w="100%">
            <Calendar
              localizer={localizer}
              events={events}
              views={["month"]}
              startAccessor="start"
              endAccessor="end"
              style={{ height: calendarHeight, width: "100%" }}
              date={selectedMonth}
              onNavigate={handleMonthChange}
              components={{ toolbar: customToolbar, event: EventComponent }}
              dayPropGetter={dayPropGetter}
              eventStyleGetter={eventStyleGetter}
            />
          </Box>
          <Box>
            <TotalHoursComponent />
          </Box>
        </HStack>
	 				<Box w="30%" border="1px solid #CCCCCC" borderRadius="1px" px="12px" py="2px">
	 					
	 						<Box mt="20px">
		 						<Text fontSize="60px" fontWeight="400" fontFamily="'Montserrat', sans-serif" style={{ lineHeight: '60px' }}>{monthHour.total_hour}:{monthHour.total_mint}</Text>
		 						<Text fontSize="16px" fontWeight="400" fontFamily="'Montserrat', sans-serif">Total Hours</Text>
	 						</Box>

	 						<Box className="Box_Area">
	 							<Text className="head_text">Hours Breakdown</Text>

	 							<Progress
                  value={totalProgress} // Total progress percentage
                  size="lg"
                  colorScheme="none"
                  sx={{
                    background: `linear-gradient(to right, ${gradient})`, // Applying the dynamic gradient
                    height: '20px',
                    borderRadius: '8px',
                  }}
                />
                {groupTasks.map((rs)=>{
                  return(
                    <>
                    <Box mt="10px">
                      <HStack justifyContent="space-between">
                        <Box>
                          <HStack>
                            <Box w="21px" h="21px" bg={rs.color}>

                            </Box>

                            <Box>
                              <Text fontSize="12px" fontWeight="400">{rs.name}</Text>
                            </Box>
                          </HStack>
                          
                        </Box>
                        <Box>
                          <Text fontSize="12px" fontWeight="400">{rs.hours}</Text>
                        </Box>
                      </HStack>
                    </Box>
                    </>
                  )
                })}
	 							

	 						</Box>


	 						
	 				

	 				</Box>
	 			</HStack>

	 		</Box>


	 		


	 		


		</div>
		</>
	)
}

export default Veiw;