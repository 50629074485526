import React,{useEffect,useState} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  Input,
  useDisclosure,
} from '@chakra-ui/react'
import {InputText,Form,InputDateField,SelectField,TextAreaField} from '../../../components/form'
import { fetchWrapper } from '../../../helpers';
import * as Yup from 'yup';


function CreateResource ({meCloseEdit,SowId,loadList,rs}){

	console.log(rs)

	const [TotalPer, SetTotalPer] = useState('')
	const [totalHours, setTotalHours] = useState(rs?.total_hour);
	const [job_role, setJobRole] = useState([])
	const [emp, setEmployee] = useState([])
	const [worked, SetWorkedDays] = useState(rs?.Allocation?.working_days)
	const [Sow, SetSow] = useState([])
	const sowdata = Sow;
	// console.log(sowdata)

	const [formData, setFormData] = useState({
	   	employee_id:rs.employee_id,
	   	role_id:rs?.Allocation.role_id,
	   	start_date:rs?.Allocation.start_date,
	   	end_date:rs?.Allocation.end_date,
	   	worked_days:worked,
	   	hour_per_day:rs.hour_per_day,
	   	notes:rs?.Allocation.notes,
  	});

  	 const FormSchema = Yup.object().shape({
	   employee_id: Yup.string().required('Required'),
	   role_id: Yup.string().required('Required'), 
	   start_date: Yup.string()
	    .required('Required')
	    .test('start-date', `Start date must be on or after the project start date (${sowdata.start_date})`, function (value) {
		  const { start_date: sowStartDate } = sowdata; 
		  return new Date(value) >= new Date(sowStartDate);
		}),

  // End Date Validation
  	end_date: Yup.string()
    .required('Required')
    .test('end-date', `End date must be after start date and before the project end date (${sowdata.end_date})`, function (value) {
      const { start_date } = this.parent; 
      const { end_date: sowEndDate } = sowdata; 
      return new Date(value) > new Date(start_date) && new Date(value) <= new Date(sowEndDate);
    }),
	   hour_per_day: Yup.string().required('Required'),
	   // notes: Yup.string().required('Required'),

	});

  	useEffect(()=>{
		loadJobList();
		loadEmplist();
		LoadSowList(SowId)
	},[SowId])


	const [startDate, SetStart] = useState(rs?.Allocation?.start_date)
	const [endDate, SetEnd] = useState(rs?.Allocation?.end_date)	

	const loadJobList = async()=>{
	 	const url = `${process.env.REACT_APP_API_URL}/api/job-role-list`;
	  	const data = await fetchWrapper.get(url)
	  	setJobRole(data.rows)
	}
	const loadEmplist = async()=>{
	 	const url = `${process.env.REACT_APP_API_URL}/api/cmd/all-employee`;
	  	const data = await fetchWrapper.get(url)
	  	setEmployee(data.rows)
	}

	const LoadSowList = async(id)=>{
    const url = `${process.env.REACT_APP_API_URL}/api/sow-list/${id}`;
      const data = await fetchWrapper.get(url)
      SetSow(data)
  }


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		let  data = values
		const url_id = window.location.href.split('/');
  		const id = url_id[url_id.length - 1];

	    data.working_days = worked
	    data.total_hour = totalHours
	    data.project_id = id;
	    data.sow_id = sowdata.id

	    console.log(data)
	    const url = `${process.env.REACT_APP_API_URL}/api/allocation-update/${rs?.Allocation?.id}`;
      	await fetchWrapper.put(url,data)   
	     meCloseEdit()
	     loadList(sowdata.id)
	}

	const handleDate = (name, e) => {
    if (name === 'start_date') {
        SetStart(e)
        const enddate = endDate
        if (enddate) {
            const start = new Date(e);
            const end = new Date(enddate);
            let workingDays = 0;
            for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
                if (date.getDay() !== 6 && date.getDay() !== 0) {
                    workingDays++;
                }
            }
            SetWorkedDays(workingDays);
             if(TotalPer){
	        	const totalHours = TotalPer * workingDays;
	            setTotalHours(totalHours);
            }
        }
    }

    if (name === 'end_date') {
        SetEnd(e)
        const startdate = startDate
        if (startdate) {
            const start = new Date(startdate);
            const end = new Date(e);
            let workingDays = 0;
            for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
                if (date.getDay() !== 6 && date.getDay() !== 0) {
                    workingDays++;
                }
            }
            SetWorkedDays(workingDays);
            if(TotalPer){
            	const totalHours = TotalPer * workingDays;
            	setTotalHours(totalHours);
            }
        }
    }
};

	const handleHours = (e)=>{
		const hoursPerDay = parseFloat(e) || 0;
		if(hoursPerDay){
			SetTotalPer(hoursPerDay)
	    	const totalHours = hoursPerDay * worked;
		    setTotalHours(totalHours);
	    }
		
    }

	return(
		<>
		
		<Form
            enableReinitialize
            validationSchema={FormSchema}
            initialValues={formData}
            onSubmit={onSubmit}
	 	>
			<HStack w="100%">
	 		
	 			<Box w="50%">
	 				<SelectField name="employee_id" title="Employee"  list={emp} />
	 			</Box>
	 			<Box w="50%">
	 				<SelectField  name="role_id" title="Role in Project" list={job_role}  />
	 			</Box>
	 		</HStack>


	 		<HStack w="100%">
	 			<Box w="50%">
	 				<InputDateField inputProps={{type:'date'}} name="start_date" title="Start Date" onChange={(name,e)=> handleDate(name,e)}  />
	 			</Box>
	 			<Box w="50%">
	 				<InputDateField inputProps={{type:'date'}} name="end_date" title="End Date" onChange={(name,e)=> handleDate(name,e)}  />
	 			</Box>
	 			<Box w="50%" className="custom">
				  <label htmlFor="worked_days" className="label">Working Days</label>
				  <Input id="worked_days" placeholder={worked} disabled  
				  	_disabled={{ 
				      opacity: 1, 
				      backgroundColor: '#CCCCCC', 
				      cursor: 'not-allowed'
				    }}  
               		_placeholder={{ color: "black" }}
				 	/>
				</Box>
	 		</HStack>


	 		<HStack w="100%"> 
	 			<Box w="40%">
	 				<InputText  name="hour_per_day" title="Hours per Day" onChange={(e)=> handleHours(e)} />
	 			</Box>
	 			
	 			<Box w="30%" className="custom">
				  <label htmlFor="Total Hours" className="label">Total Hours</label>
				  <Input id="Total Hours" placeholder={totalHours} disabled 
				  	_disabled={{ 
				      opacity: 1, 
				      backgroundColor: '#CCCCCC', 
				      cursor: 'not-allowed'
				    }}    
                    _placeholder={{ color: "black" }}
                    />
				</Box>
	 		</HStack>


 			<Box w="100%">
 				<TextAreaField  name="notes" title="Notes" />
 			</Box>


	 		<HStack w="40%">
	 		
	 			<Box me="5px">
	 				<Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif"
			            mb={4}
			            
			            type='submit'
	          		>
	           		Save
	          		</Button>
	 			</Box>
	 			<Box w="50%" >
	 				<Button py="10px" px="25px" color="#787878" bg="#fff" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif" border="1px solid #CCCCCC"
			            mb={4}
			            onClick={meCloseEdit}
			            
			            type='button'
	          		>
	           		Cancel
	          		</Button>
	 			</Box>
	 		</HStack>

 		</Form>	
		</>
	)
}

export default CreateResource;
