
import React,{useState,useEffect} from 'react';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
   Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Spinner
} from '@chakra-ui/react'
import { fetchWrapper } from '../../helpers';
import emp_user from '../../images/Ellipse 9.png'
import { BsClock } from "react-icons/bs";
import { FaRegCircleUser } from "react-icons/fa6";
import secureStorage from "react-secure-storage";
function  Employee() {
 const id = secureStorage.getItem("userId");
	const [list,setList] = useState([])
	const [loading,setLoading] = useState(false);

	useEffect(() => {
        loadList()
    }, []);

  	const loadList = async () => {
  		setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/api/cmd/all-employee`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows)
      setLoading(false)
  	}

  	const meSync = async(type)=>{
  		setLoading(true)
  		// console.log(type)
  		const data = {
  			type : type
  		}
  		const url = `${process.env.REACT_APP_API_URL}/api/cmd/employee`;
     	await fetchWrapper.post(url,data)
     	
     	setTimeout(async () => {
     		
     		await loadList();
     		console.log('dd')
     		 setLoading(false);
     		
     	}, 2000)
     	// setLoading(false)
  	}


	return(
		<>
		{loading &&
        <HStack width="100%" h="100vh" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
          <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      </HStack>
    }
			<Box px="45px" py="20px">
				<HStack justifyContent="space-between">
					<Box>
						<Heading fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000">Employees</Heading>
					</Box>
					<Box cursor="pointer" onClick={()=> meSync('sync')}>
						<Heading fontSize="20px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000">Sync</Heading>
					</Box>
				</HStack>
				
			</Box>
		 <div className="page_section">	
			
			<Box display="flex" flexWrap="wrap" mb="20px">
			    {list.map((rs,index) => {
			        return(
			            <Link key={index} me="20px" mt="20px" w="308px"  cursor="pointer"  style={{textDecoration :'none'}}>
			                <HStack className="employee_box" justifyContent="space-between" border="1px solid #ccc" px="10px" py="20px" borderRadius="10px" alignItems="end">
			                    {/*<Box>
			                        <Image src={emp_user} alt="Employee User" />
			                    </Box>*/}
			                	<HStack>
			                		<Box
									          width="70px"
									          height="70px"
									          bg="#ccc"
									          display="flex"
									          alignItems="center"
									          justifyContent="center"
									          borderRadius="50%"

									        >
									          <Text fontWeight="400" fontSize="24px" color="#000">
									            {rs.name.charAt(0).toUpperCase()}
									          </Text>
									        </Box>
			                    <Box marginLeft="10px" className="text_section">
			                        <Heading style={{fontSize:'16px'}}>
			                            {rs.name}
			                        </Heading>
			                        <Text>
			                           {rs.designation}
			                        </Text>
			                        <Text>
			                            {rs.department}
			                        </Text>

			                        
			                    </Box>
			                    </HStack>
			                    
			                    		<HStack justifyContent="end" alignItems="end">
			                    			<Box>
			                        		<FaRegCircleUser/>
			                        	</Box>
			                        	<Box>
			                        		<Link href={`/veiw/${rs.id}`}>
		                        				<BsClock/>
		                        			</Link>
			                        	</Box>			                        	
			                        </HStack>
			                   
			                </HStack>
			            </Link>
			        )
			    })}
			</Box>


			


		</div>

		</>
	)
}

export default Employee