import React,{useEffect,useState} from 'react';
import CustomerModalLink from '../../components/CustomerModalLink'
import * as Yup from 'yup';
import Create from './scope_worked/Create'
import {InputText,Form,TextAreaField,SelectField} from '../../components/form'
import { fetchWrapper } from '../../helpers';
import Header from '../../components/Header';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
} from '@chakra-ui/react'
import {useLocation,useNavigation } from "react-router-dom";
import OverView from './overview'
import Scope_Worked from './scope_worked/List'
import Budgets from './budgets/List'
import Allocations from './allocations/List'
import Files from './files/List'
import { debounce } from 'lodash';
import { BsArrowLeftShort } from "react-icons/bs";


function Edit(){
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const {state} = useLocation();
	const rs = state?.rs

 	const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'Overview');
	const [show,setShow] = useState(false);
	 const [list,setList] = useState([])
	   useEffect(() => {
        loadList()
       
       
        
    }, [show]);
	  const loadList = async () => {
	  	const url_id = window.location.href.split('/');
  		const id = url_id[url_id.length - 1];
      const url = `${process.env.REACT_APP_API_URL}/api/project-list/${id}`;
      const data  = await fetchWrapper.get(url)
      setList(data)
  }

   const handleTabChange = (index) => {
    const tabMapping = ['Overview', 'Worked', 'Budgets', 'Allocations', 'TimeSheets', 'Files'];
    const selectedTab = tabMapping[index];
    setActiveTab(selectedTab);
    localStorage.setItem('activeTab', selectedTab); // Save the tab to localStorage
  };
 

// 	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]
  const meClose = () => {
  		
		   onClose();
		   
	}

	 const onToggle = () => {
  		
		   setShow(!show);
		   
	}

	return (

			<>
			<Header/>


	 	<div className="Project_Edit_page_Section">
	 		<HStack cursor="pointer" onClick={()=> window.location.href="/project"}>
 				<Box className="Backedicon">
					<BsArrowLeftShort />
				</Box>
				<Box className="Backed">
					<Text>Back to Projects List</Text>
				</Box>
	 		</HStack>
 			
	 			<HStack mb="30px" justifyContent="space-between">
	 				<Box>
						<Text fontSize="32px" fontWeight="300" color="#000" my="0px" fontFamily="'Montserrat', sans-serif" textAlign="Start">{list?.name}</Text>
					</Box>

					<HStack>
						<Box>
							 {activeTab === 'Worked' ? (
						    <CustomerModalLink
						        showModalButtonText="Add New SOW"
						        modalHeader="Add New SOW"
						        isOpen={isOpen}
						        onClose={onClose}
						        onOpen={onOpen}
						        modalBody={<Create meClose={meClose} onToggle={onToggle} />}
						    />
						) : null}

						</Box>
						<Box>
							 <Button py="10px" px="25px" color="#fff" bg="#4ECE39" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif" borderRadius="50px"
					            mb={0}>
			          			{list?.status_id === 1 ? 'Active' : list?.status_id === 2 ? 'On Hold' : 'Completed'}
			          		</Button>
						</Box>
					</HStack>
	 			</HStack>
	     	 	
				

 			<Tabs 
          position="relative" 
          variant="unstyled" 
          index={['Overview', 'Worked', 'Budgets', 'Allocations', 'TimeSheets', 'Files'].indexOf(activeTab)} // Set the tab index based on the activeTab
          onChange={handleTabChange} // When a tab is clicked, update activeTab and save to localStorage
        >
				<hr/>
			    <TabList className="TabsList">
			      <Tab>Overview</Tab>
		            <Tab>Scope of Work</Tab>
		            <Tab>Budgets</Tab>
		            <Tab>Allocations</Tab>
		            <Tab>Time Sheets</Tab>
		            <Tab >Files</Tab>
			    </TabList>
			    <hr/>
			    
			   	<TabPanels>
		            <TabPanel width="50%" mt="20px">
		                {activeTab === "Overview" && <OverView rs={rs} pl={list}  onToggle={onToggle} show={show}/>}
		            </TabPanel>

		            <TabPanel>
		                {activeTab === "Worked" && <Scope_Worked show={show}/>}
		            </TabPanel>

		            <TabPanel>
		                {activeTab === "Budgets" && <Budgets />}
		            </TabPanel>

		            <TabPanel>
		                {activeTab === "Allocations" && <Allocations/>}
		            </TabPanel>

		            <TabPanel>
		                {activeTab === "TimeSheets" && <Text>TimeSheets</Text>}
		            </TabPanel>

		             <TabPanel>
		                {activeTab === "Files" &&  <Files/>}
		            </TabPanel>
				</TabPanels>  
			</Tabs>



			

		</div>		

			</>

	)
}

export default Edit;