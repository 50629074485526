import React,{useEffect,useState} from 'react';
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../../components/form'
import { fetchWrapper } from '../../../helpers';
import CustomModalLink from '../../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

import CreateConsultant from './CreateConsultant'
import CreateResource from './CreateResource'
import EditResource from './EditResource'

function List() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
  const [isOpenEdit,setIsOpenEdit] = useState(false)   
  const [modalEditView,setModalEditView] = useState(false)


  
  const [selectedSow, setSelectedSow] = useState(null)  
  const [status, setStatus] = useState(0)
  const [balance, setBalance] = useState(0)
  const [Sow, SetSow] = useState([])
  const [heading, setHeading] = useState([])
  const [Data, setData] = useState([])

  const [perMonth, SetPerMonth] = useState([])

  // console.log(Data)

  const [formData, setFormData] = useState({
    name: '',
  });

  // const Sow = [
  //   {id:'1',name:'Digital Connect - 1001'},
  //   {id:'2',name:'One Place Safe'}
  // ]

  const meCloseEdit = () => {
      
       setIsOpenEdit(false);
       
  }

  const meOpenModel = (veiw,rs) => {
    setIsOpenEdit(true);
    if(veiw == 'consultant'){
      setModalEditView(<CreateConsultant meCloseEdit={meCloseEdit}/>)     
    }
    if(veiw == 'resource'){
      setModalEditView(<CreateResource meCloseEdit={meCloseEdit} SowId={selectedSow} loadList={loadList}/>)     
    }

    if(veiw == 'edit'){
      setModalEditView(<EditResource meCloseEdit={meCloseEdit} SowId={selectedSow} rs={rs} loadList={loadList}/>)     
    }
    
  }

  useEffect(()=>{

    const url_id = window.location.href.split('/');
    const id = url_id[url_id.length - 1];
    LoadSowList(id)

    
  },[heading])

  const LoadSowList = async(id)=>{
    const url = `${process.env.REACT_APP_API_URL}/api/sow-list?project_id=${id}`;
      const data = await fetchWrapper.get(url)
      SetSow(data.rows)
  }
  const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const LoadmonthList = async(id)=>{
      const url = `${process.env.REACT_APP_API_URL}/api/budget-list?id=${id}`;
      const data = await fetchWrapper.get(url)
      // SetPerMonth(data.rows)
      const sortedData = data.rows.sort((a, b) => {
        const [monthA, yearA] = a.month.split(' ');
        const [monthB, yearB] = b.month.split(' ');

      
        if (yearA !== yearB) {
          return yearA - yearB;
        }

        return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
        // return monthOrder.indexOf(a.month.split(' ')[0]) - monthOrder.indexOf(b.month.split(' ')[0]);

      });
      
      SetPerMonth(sortedData)
  }


  const handleSelectChange = (value) => {
    setSelectedSow(value);
    if(value){
      loadHeader(value)
      loadList(value)
      LoadmonthList(value)
    }
    
    setFormData({ ...formData, name: value });
  };

  const loadHeader = async(value)=>{
    const url = `${process.env.REACT_APP_API_URL}/api/sow-list/${value}`;
      const data = await fetchWrapper.get(url)
      setHeading(data)
      // console.log(data)       
  }

  const monthMap = {
    Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
    Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
  };

const loadList = async(value) => {
  // console.log(value)
  const url = `${process.env.REACT_APP_API_URL}/api/all-detail-list?sow_id=${value}`;
  const data = await fetchWrapper.get(url);
  setData(data?.rows);
  console.log(data)

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const currentMonthStatusCount = data?.rows?.filter(rs => {
    return (
      rs.month === currentMonth && 
      rs.year === currentYear &&   
      rs.status_id === 1           
    );
  }).length || 0;

  const balance = data.rows.reduce((total, rs) => total + rs.total_salary, 0);
  setBalance(balance)
    
  setStatus(currentMonthStatusCount);
}


  return (
    <>
    <CustomModalLink
        showModalButtonText={`SOW: ${heading.name}`}
        modalHeader={`SOW: ${heading.name}`}
        isOpenEdit={isOpenEdit}
        onCloseEdit={meCloseEdit}
        onOpenEdit={onOpenEdit}
        
        modalBody={modalEditView}
      />
    <Form
                  enableReinitialize
                  // validationSchema={FormSchema}
                  initialValues={formData}
                  // onSubmit={onSubmit}
       >
       <HStack justifyContent="space-between">
          <Box w="50%">
            <SelectField name="name" title="SOW" list={Sow} onChange={handleSelectChange}/>
          </Box>
           {selectedSow && ( 
              <HStack justifyContent="end">
                <Box color="#fff" fontSize="14px" fontWeight="400">
                  <div onClick={() => meOpenModel('consultant')} id="button1">Add a Consultant</div>
                </Box>
                <Box color="#fff" fontSize="14px" fontWeight="400">
                  <div onClick={() => meOpenModel('resource')} id="button2">Allocate a Resource</div>
                </Box>
              </HStack>
            )}
       </HStack>
      

       {/*Box Area */}

       <HStack mb="20px">
          

          <Box borderWidth="1px" borderColor="#ccc" borderRadius="5px" w="100%" me="15px" h="115px" bg="#288BFF">
            <HStack justifyContent="space-between" >
              <Box py="10px" px="15px" >
              <Text fontSize="32px" fontWeight="600" color="#FFFFFF" mb="0" >Rs.{selectedSow ?  (heading.total_budget ? parseInt(heading.total_budget).toLocaleString() : '0')  : '0'}</Text>
              <Text color="#FFFFFF" fontWeight="400" fontSize="14px">Total Budget</Text>
            </Box>
            </HStack>
          </Box>

          <Box borderWidth="1px" borderColor="#ccc" w="100%" borderRadius="5px" me="15px" h="115px" bg="#FB5656">
            <HStack justifyContent="space-between">
              <Box py="10px" px="15px" >
              <Text fontSize="32px" fontWeight="600" color="#FFFFFF" mb="0" >Rs.{selectedSow ? parseInt(balance).toLocaleString()  : '0'}</Text>
              <Text color="#FFFFFF" fontWeight="400" fontSize="14px">Budget Utilized</Text>
            </Box>
            
            </HStack>
          </Box>

          <Box borderWidth="1px" borderColor="#ccc" w="100%" borderRadius="5px" me="15px" h="115px" bg="#5ED84A">
            <HStack justifyContent="space-between">
              <Box py="10px" px="15px" >
              <Text fontSize="32px" fontWeight="600" color="#FFFFFF" mb="0" >Rs.{selectedSow ? parseInt(heading.total_budget - balance).toLocaleString() : '0' }</Text>
              <Text color="#FFFFFF" fontWeight="400" fontSize="14px">Balance</Text>
            </Box>
            
            </HStack>
          </Box>

          <Box borderWidth="1px" borderColor="#ccc" w="100%" borderRadius="5px" me="15px" h="115px" bg="#31367A">
            <HStack justifyContent="space-between">
              <Box py="10px" px="15px" >
              <Text fontSize="32px" fontWeight="600" color="#FFFFFF" mb="0" >{selectedSow ?   status : '0'}</Text>
              <Text color="#FFFFFF" fontWeight="400" fontSize="14px">Active Team Members</Text>
            </Box>
            
            </HStack>
          </Box>
        </HStack> 
        
        {/* Table Area*/}
        {selectedSow && (perMonth && perMonth.length > 0 ? (
          perMonth.map((month, index) => {
            const monthString = month.month;
            const [monthAbbr, year] = monthString.split(" ");

            const numericMonth = monthMap[monthAbbr];
            const numericYear = parseInt(year, 10); 

            const filteredData = Data.filter(rs => rs.month === numericMonth && rs.year === numericYear);

            return (
              <div key={index}>
                <HStack justifyContent="space-between" borderWidth="1px" borderColor="#ccc" bg="#CCCCCC" h="40px" px="20px">
                  <Box>
                    <Text fontSize="14px" fontWeight="600" color="#000000">{month.month}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="14px" fontWeight="600" color="#000000">Month’s Budget: Rs {month.budget}</Text>
                  </Box>
                </HStack>

                <div className="table_section" style={{ marginBottom: '30px' }}>
                  <TableContainer width="100%">
                    <Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
                      <Thead>
                        <Tr>
                          <Th>S Code</Th>
                          <Th>Name</Th>
                          <Th>Role in Project</Th>
                          <Th>Daily Allocated Hours</Th>
                          <Th>Allocated Hours</Th>
                          <Th>Days Off</Th>
                          <Th>Holidays</Th>
                          <Th>Total Cost</Th>
                          <Th></Th>
                        </Tr>
                      </Thead>

                      <Tbody>
                        {filteredData.length > 0 ? (
                          filteredData.map((rs, index) => (
                            <Tr key={index}>
                              <Td>{rs.id}</Td>
                              <Td w="20%">
                                <Text fontWeight="400" fontSize="14px">{rs?.Employee?.name}</Text>
                                <Text fontWeight="400" fontSize="12px">{rs?.Employee?.designation}</Text>
                              </Td>
                              <Td>{rs?.Allocation?.JobRole?.name}</Td>
                              <Td>{rs?.hour_per_day} Hours</Td>
                              <Td>{rs.total_hour} Hours</Td>
                              <Td>
                                <Text fontWeight="400" fontSize="14px">2 Days</Text>
                                <Text fontWeight="400" fontSize="12px">16 Hours</Text>
                              </Td>
                              <Td>
                                <Text fontWeight="400" fontSize="14px">2 Days</Text>
                                <Text fontWeight="400" fontSize="12px">16 Hours</Text>
                              </Td>
                              <Td>Rs {rs.total_salary ? rs.total_salary.toLocaleString() : '0'}</Td>
                              <Td style={{ color: "#0085FF" }}>
                                <Menu>
                                  <MenuButton fontSize="14px">
                                    Action <ChevronDownIcon />
                                  </MenuButton>
                                  <MenuList style={{ minWidth: "20px", border: "1px solid #CCCCCC" }}>
                                    <MenuItem h="16px" fontSize="12px" onClick={()=> meOpenModel('edit',rs)}>Edit</MenuItem>
                                  </MenuList>
                                </Menu>
                              </Td>
                            </Tr>
                          ))
                        ) : (
                          <Tr>
                            <Td colSpan="9">
                              <Text fontWeight="400" fontSize="14px" textAlign="center">
                                No data available for this month.
                              </Text>
                            </Td>
                          </Tr>
                        )}
                      </Tbody>

                       <Tfoot bg="#CCCCCC">
                        <Tr>
                          <Th>Total</Th>
                          <Th>{filteredData.length} Resources</Th>
                          <Th></Th>
                          <Th>{filteredData.reduce((total, rs) => total + rs.hour_per_day, 0)} Hours</Th>
                          <Th>{filteredData.reduce((total, rs) => total + rs.total_hour, 0)} Hours</Th>
                          <Th>4 Days</Th>
                          <Th>5 Days</Th>
                          <Th>
                            Rs {filteredData.reduce((total, rs) => total + (Number(rs.total_salary) || 0), 0).toLocaleString()}
                          </Th>

                          <Th></Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            );
          })
        ) : (
          <Box>
            <Text fontSize="16px" fontWeight="600" color="#FF0000" textAlign="center">
              Add monthly budget 
            </Text>
          </Box>
        ))}
       
      </Form>                  
    </>  
  );
}

export default List;