import React,{useEffect,useState} from 'react';
import { fetchWrapper } from '../../../helpers';
import Create from './Create'
import Edit from './Edit'
import CustomerModalLink from '../../../components/CustomerModalLink'
import CustomModalLink from '../../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

function List({show}) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [list,setList] = useState([])

	useEffect(() => {
		const url_id = window.location.href.split('/');
  		const id = url_id[url_id.length - 1];
        loadList(id)
    }, [show]);

  	const loadList = async (id) => {
      const url = `${process.env.REACT_APP_API_URL}/api/sow-list?project_id=${id}`;
      const data  = await fetchWrapper.get(url)
      setList(data.rows)
  	}

  	const activeList = list.filter((item) => item.status_id === 1);
		const holdList = list.filter((item) => item.status_id === 2);
		const completedList = list.filter((item) => item.status_id === 10);

  	const meClose = () => {
  		
		   onClose();
		   
	}
	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}
	const meChange = async(type,rs)=>{
		if(type === "hold"){
			const data = {
				status_id : 2,
				id:rs.id
			}

			const url = `${process.env.REACT_APP_API_URL}/api/sow-update/${rs.id}`;
      await fetchWrapper.put(url,data)
      loadList(rs.project_id)

		}

		if(type === "completed"){
			const data = {
				status_id : 10,
				id:rs.id
			}

			const url = `${process.env.REACT_APP_API_URL}/api/sow-update/${rs.id}`;
      await fetchWrapper.put(url,data)
      loadList(rs.project_id)

		}
		
	}

	const meEdit = (rs)=>{
		setIsOpenEdit(true);
		setModalEditView(<Edit rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)

	}

	return(
		<>
		<CustomModalLink
        showModalButtonText="Edit"
        modalHeader="Edit"
        isOpenEdit={isOpenEdit}
        onCloseEdit={meCloseEdit}
        onOpenEdit={onOpenEdit}
        size="xl"
        modalBody={modalEditView}
      />
		{/* Active */}
		<HStack justifyContent="space-between" >
			<Box>
			<Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Montserrat', sans-serif">Active</Text>
			</Box>

			
		</HStack>
		<div className="table_section">
			<TableContainer width="100%">
		  		<Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
				    <Thead>
					      <Tr>
					        <Th>SOW Name</Th>
				         	<Th>Start Date</Th>
				          	<Th>End Date</Th>
				          	<Th>No of Resources</Th>
					        <Th>Total SOW Hours</Th>
					        <Th>Status</Th>
					       
					        <Th></Th>
					      </Tr>
				    </Thead>
				    <Tbody>	
					    {
					    	activeList.length === 0 ? (
						    <Tr>
						      <Td colSpan="7" textAlign="center">
						        No data found
						      </Td>
						    </Tr>
							  ) : (
					    	activeList.map((rs,index)=>{
		            	return(
		                   <Tr key={index}>
			                    <Td style={{color:"#3B86FF"}}>{rs.name}</Td>
			                    <Td>{rs.start_date}</Td>
			                    <Td>{rs.end_date}</Td>
			                   <Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.no_of_resources, 0)}</Td>
			                    <Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.total_hours, 0)}</Td>
			                    <Td>{rs.status_id === 1 ? 'InProcess' :"Hold" }</Td>

			                    <Td style={{color:"#0085FF"}}>
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                              	<MenuItem  h="16px" fontSize="12px" onClick={() => meEdit(rs)} >Edit</MenuItem>
                              	<MenuDivider/>
                                <MenuItem  h="16px" fontSize="12px" onClick={() => meChange('hold',rs)} >Place on Hold</MenuItem>
                                <MenuDivider />
                                <MenuItem h="16px" fontSize="12px">Delete</MenuItem>
                                
                              </MenuList>
                            </Menu>
                            </Td>
			                    
		                    </Tr>
		              	)
		          		}))}				        
				    </Tbody> 
			  	</Table>
			</TableContainer>
				</div>


		
		{/* Hold */}

		<Box mt="20px" mb="20px">
			<HStack justifyContent="space-between" >
				<Box>
				<Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Montserrat', sans-serif">Hold</Text>
				</Box>

				
			</HStack>
			<div className="table_section">
			<TableContainer width="100%">
		  		<Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
				    <Thead>
					      <Tr>
					        <Th>SOW Name</Th>
				         	<Th>Start Date</Th>
				          	<Th>End Date</Th>
				          	<Th>No of Resources</Th>
					        <Th>Total SOW Hours</Th>
					        <Th>Status</Th>
					       
					        <Th></Th>
					      </Tr>
				    </Thead>
				    <Tbody>	
					    {
					    	holdList.length === 0 ? (
							    <Tr>
							      <Td colSpan="7" textAlign="center">
							        No data found
							      </Td>
							    </Tr>
							  ) : (
					    	holdList.map((rs,index)=>{
		            	return(
		                   <Tr key={index}>
			                    <Td style={{color:"#3B86FF"}}>{rs.name}</Td>
			                    <Td>{rs.start_date}</Td>
			                    <Td>{rs.end_date}</Td>
			                   	<Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.no_of_resources, 0)}</Td>
			                    <Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.total_hours, 0)}</Td>
			                    <Td>{rs.status_id === 2 ? 'Hold' :"Completed" }</Td>

			                    <Td style={{color:"#0085FF"}}>
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                 <MenuItem  h="16px" fontSize="12px" onClick={() => meChange('completed',rs)} >Place on Completed</MenuItem>
                                <MenuDivider />
                                <MenuItem h="16px" fontSize="12px">Delete</MenuItem>
                                
                              </MenuList>
                            </Menu>
                            </Td>
			                    
		                    </Tr>
		              	)
		          		}))}				        
				    </Tbody> 
			  	</Table>
			</TableContainer>
				</div>				
		</Box>

		{/* Completed */}
		<HStack justifyContent="space-between">
			<Box>
			<Text fontSize="16px" fontWeight="600" color="#000000" my="0px" fontFamily="'Montserrat', sans-serif">Completed</Text>
			</Box>

			
		</HStack>
		<div className="table_section">
			<TableContainer width="100%">
		  		<Table variant='simple' width="100%" borderWidth="1px" borderColor="#CCCCCC" borderStyle="solid">
				    <Thead>
					      <Tr>
					        <Th>SOW Name</Th>
				         	<Th>Start Date</Th>
				          	<Th>End Date</Th>
				          	<Th>No of Resources</Th>
					        <Th>Total SOW Hours</Th>
					        <Th>Status</Th>
					       
					        <Th></Th>
					      </Tr>
				    </Thead>
				    <Tbody>	
					    {
					    	completedList.length === 0 ? (
							    <Tr>
							      <Td colSpan="7" textAlign="center">
							        No data found
							      </Td>
							    </Tr>
							  ) : (
					    	completedList.map((rs,index)=>{
		            	return(
		                   <Tr key={index}>
			                    <Td style={{color:"#3B86FF"}}>{rs.name}</Td>
			                    <Td>{rs.start_date}</Td>
			                    <Td>{rs.end_date}</Td>
			                   	<Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.no_of_resources, 0)}</Td>
			                    <Td>{rs.Sow_Resources.reduce((total, resource) => total + resource.total_hours, 0)}</Td>
			                    <Td>{rs.status_id === 10 ? 'Completed' :"Hold" }</Td>

			                    <Td style={{color:"#0085FF"}}>
                            <Menu>
                              <MenuButton fontSize="14px" >
                              Action
                                
                                  <ChevronDownIcon />
                              </MenuButton>

                              <MenuList  style={{minWidth:"20px",border:"1px solid #CCCCCC"}}>
                                <MenuItem h="16px" fontSize="12px">Delete</MenuItem>
                                
                              </MenuList>
                            </Menu>
                            </Td>
			                    
		                    </Tr>
		              	)
		          		}))}				        
				    </Tbody> 
			  	</Table>
			</TableContainer>
				</div>				
		</>
	)
}

export default List;