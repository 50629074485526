import React, { useState, useEffect } from 'react';
import { fetchWrapper } from '../helpers';

export function ViewUser({ created_by }) {
  const [userName, setUserName] = useState('');

  useEffect(() => {
    // Function to load user data based on user_id
    const loadUserName = async () => {
      try {
        if (created_by) {
          const url = `${process.env.REACT_APP_API_URL}/api/user-list/${created_by}`;
          const user = await fetchWrapper.get(url);
          setUserName(user.username || 'N/A');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUserName('Unknown');
      }
    };

    loadUserName();
  }, [created_by]);

  return (
    <span>{userName || 'Unknwon User' }</span>
  );
}
