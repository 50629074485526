import React,{useEffect,useState} from 'react';

import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
} from '@chakra-ui/react'
import {InputText,Form,TextAreaField,SelectField,InputDateField} from '../../components/form'

import { fetchWrapper } from '../../helpers';


function Overveiw ({rs,pl,onToggle}){

	useEffect(() => {
    setFormData({
        name: pl?.name,
        project_overview: pl?.project_overview,
        project_type_id: pl?.project_type_id,
        business_unit_id: pl?.business_unit_id,
        project_manage_id: pl?.project_manage_id,
        client_id: pl?.client_id,
        start_date: pl?.start_date,
        end_date: pl?.end_date,
        id: pl?.id
    });
}, [pl]);


	const [projecttype,setProjectType]= useState([]);
	const [team,setTeam] = useState([]);
	const [business,setBusinessUnit]= useState([]);
	const [projectmanage,setProjectManage]= useState([]);
	const [client,setClient]= useState([]);

	const [formData, setFormData] = useState({
	    name: rs?.name,
	    project_overview: rs?.project_overview,
	    project_type_id: rs?.project_type_id,
	    // team_id: rs?.team_id,
	    business_unit_id: rs?.business_unit_id,
	    project_manage_id: rs?.project_manage_id,
	    client_id: rs?.client_id,
	    start_date: rs?.start_date,
	    end_date: rs?.end_date,
	    id:rs?.id
	    
	  });

  	const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   project_overview: Yup.string().required('Required'),
	   project_type_id: Yup.string().required('Required'),
	   // team_id: Yup.string().required('Required'),
	   business_unit_id: Yup.string().required('Required'),
	   project_manage_id: Yup.string().required('Required'),
	   client_id: Yup.string().required('Required'),
	   start_date: Yup.string().required('Required'),
	   end_date: Yup.string().required('Required'),
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  	const url_id = window.location.href.split('/');
    		const id = url_id[url_id.length - 1];

	       
	     const url = `${process.env.REACT_APP_API_URL}/api/project-update/${id}`;
  		 await fetchWrapper.put(url,data);
  		 onToggle();
  		 alert('Data Saved');
  		 // window.location.href="/project"
  		 // loadList()
	}

	useEffect(()=>{

		ProjectTypeList()
		TeamList()
		BusinessUnitList()
		ProjectManageList()
		ClientList()
	},[rs])

	const ProjectTypeList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/project-type-list`;
  		const data = await fetchWrapper.get(url)
  		setProjectType(data.rows)
	}
	const TeamList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/team-list`;
  		const data = await fetchWrapper.get(url)
  		setTeam(data.rows)
	}

	const BusinessUnitList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/business-list`;
  		const data = await fetchWrapper.get(url)
  		setBusinessUnit(data.rows)
	}

	const ProjectManageList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/project-manage-list`;
  		const data = await fetchWrapper.get(url)
  		setProjectManage(data.rows)
	}


	const ClientList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/client-list`;
  		const data = await fetchWrapper.get(url)
  		setClient(data.rows)
	}

	return(
		<>
				<Form
						            enableReinitialize
						            validationSchema={FormSchema}
						            initialValues={formData}
						            onSubmit={onSubmit}
						 >


						 	<InputText name="name" title="Project Name"  />
						 	<TextAreaField name="project_overview" title="Project Overview" />

						 	<SelectField name="project_type_id" list={projecttype}  title="Project Type"/>
						 	{/*<SelectField name="team_id" list={team}  title="Team"/>*/}
						 	<SelectField name="business_unit_id" list={business}  title="Business Unit"/>
						 	<SelectField name="project_manage_id" list={projectmanage}  title="Project Manager"/>
							<SelectField name="client_id" list={client}  title="Client(Optional)"/>
							<HStack w="100%">
								<Box w="50%">
									<InputDateField name="start_date" inputProps={{type:'date'}}  title="Start Date"/>
								</Box>
								<Box w="50%">
									<InputDateField name="end_date" inputProps={{type:'date'}}  title="End Date"/>
								</Box>
							</HStack>
					         <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
							            mb={4}
							            
							            type='submit'
					          		>
					           		 Update
					          		</Button>

						</Form>
		</>
	)
}

export default Overveiw;