import React,{useEffect,useState} from 'react';
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField,TextAreaField} from '../../components/form'
import { fetchWrapper } from '../../helpers';

import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs, TabList, TabPanels, Tab, TabPanel,TabIndicator,
  Button,
  Link,
  useDisclosure,
} from '@chakra-ui/react'

import secureStorage from "react-secure-storage";





function AddTime({meCloseEdit,emp_id,loadList}) {
	// console.log(emp_id)
	const [projectList, setProjectList] = useState([])
	const [taskList, setTaskList] = useState([])
	const user_id = secureStorage.getItem("userId");

	const [formData, setFormData] = useState({
    	project_id: '',
    	task_type_id: '',
    	task_date: '',
    	task_time: '',
    	employee_id:''
  	});

  	const FormSchema = Yup.object().shape({
	   project_id: Yup.string().required('Required'),
	   task_type_id: Yup.string().required('Required'),
	   task_date: Yup.string().required('Required'),
	   task_time: Yup.string().required('Required'),
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		   data.employee_id = emp_id;
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/api/timesheet-create`;
  		 await fetchWrapper.post(url,data)
  		 loadList(emp_id)
  		 meCloseEdit();
	}

	const timeList = [];
const totalMinutes = 6 * 60; 

for (let minutes = 15; minutes <= totalMinutes; minutes += 15) {
  let hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;

  // Formatting the id as HH:mm
  const id = `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;

  // Formatting the name as "X hours Y mins"
  let name = '';
  if (hours > 0) {
    name += `${hours} hour${hours > 1 ? 's' : ''}`;
  }
  if (remainingMinutes > 0) {
    name += (hours > 0 ? ' ' : '') + `${remainingMinutes} min${remainingMinutes > 1 ? 's' : ''}`;
  }

  // Pushing each interval into the array
  timeList.push({ id: id, name: name });
}




	useEffect(()=>{
		loadProjectList(user_id);
		loadTaskList();
	},[user_id])

	const loadProjectList = async(user_id)=>{
		const url = `${process.env.REACT_APP_API_URL}/api/project-list/${user_id}`;
      const data  = await fetchWrapper.get(url)
      setProjectList(data.rows)
	}

	const loadTaskList = async()=>{
		const url = `${process.env.REACT_APP_API_URL}/api/task-list`;
      const data  = await fetchWrapper.get(url)
      setTaskList(data.rows)
	}







	return(
		<>

		<Form
			enableReinitialize
            validationSchema={FormSchema}
            initialValues={formData}
            onSubmit={onSubmit}
		>
			<SelectField  name="project_id" title="Project" list={projectList}/>
			<SelectField  name="task_type_id" title="Task Type" list={taskList}/>
			

			<HStack w="100%">
				<Box w="50%">
					<InputDateField inputProps={{type:'date'}} name="task_date" title="Date"/>
				</Box>
				<Box w="50%">
					<SelectField  name="task_time" title="Time" list={timeList}/>
				</Box>
			</HStack>
 
			<TextAreaField name="task_description" title="Task Description" placeholder="Please enter task details what have you worked on. If you have Jira Ticket ID please mention that" />

			<HStack>
				<Box>
								<Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="12px" fontWeight="400" fontFamily="'Open Sans', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           Add Time
		          		</Button>
				</Box>
				<Box>
								<Button border="1px" borderColor="#CCCCCC" py="10px" px="25px" color="#000000" bg="#FFFFFF" fontSize="12px" fontWeight="400" fontFamily="'Open Sans', sans-serif"
				            mb={4}
				            onClick={()=> meCloseEdit()}
		          		>
		           Cancel
		          		</Button>
				</Box>
			</HStack>


		</Form>
		</>
	)
}

export default AddTime;