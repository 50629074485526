import React,{useEffect,useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
const CustomModalLink = ({className, showModalButtonText, modalHeader, modalBody,isOpenEdit,size, onOpenEdit, onCloseEdit,customModalStyles,customOverlayStyles,customHeaderStyles}) => {
  

  return (
    <>
     

      <Modal isOpen={isOpenEdit} size={size ? 'l' : 'xl'} onClose={onCloseEdit}>
        <ModalOverlay sx={customOverlayStyles} />
        <ModalContent containerProps={className === 'side_popup' ? customModalStyles : ''}  minHeight={className === 'side_popup' ? '100vh' : 'auto'} my={className === 'side_popup' ? '0px' : '10px'}>
          <ModalHeader  sx={customHeaderStyles} >{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalBody}

            
          </ModalBody>

          
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModalLink;