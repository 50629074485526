import React, { useEffect, useState } from 'react';
import { BsPencil, BsFillTrashFill } from "react-icons/bs";
import * as Yup from 'yup';
import { InputText, Form, InputDateField, SelectField,SelectFieldSearch } from '../../../components/form'
import { fetchWrapper } from '../../../helpers';

import {
    Box,
    HStack,
    Input,
    Text,
    Link,
    Button,
} from '@chakra-ui/react'

function Edit({ rs, meCloseEdit,loadList }) {
    console.log(rs)
    const [job_role, setJobRole] = useState([])

    const [FullMultiHours, setFullMultiHours] = useState(0);

    
    const [formData, setFormData] = useState({
        name: rs.name,
        start_date: rs.start_date,
        end_date: rs.end_date,
        week: 0,
        worked_days: 0,
        job_roles: rs.Sow_Resources.map(resource => ({
            id:resource.id,
            sow_id:resource.sow_id,
            job_role_id: resource.job_role_id,
            total_hours: resource.total_hours,
            no_of_resources: resource.no_of_resources,
        })),
        project_id:rs.project_id,
        id:rs.id
    });

    const [initialValues, setInitialValues] = useState(null);
    const [formSchema, setFormSchema] = useState(null);
    const [worked, SetWorkedDays] = useState('0')

    const [Weeks, setWeeks] = useState('0')

    const [startDate, SetStart] = useState(rs.start_date ? rs.start_date : '')
		const [endDate, SetEnd] = useState(rs.end_date ? rs.end_date : '');

    useEffect(() => {
        loadJobList();
        setFormSchema(getInitialFormSchema());
    }, []);
    useEffect(() => {
        if (rs) {
            SetStart(rs.start_date);
            SetEnd(rs.end_date);
            handleDate('start_date', rs.start_date);
            handleDate('end_date', rs.end_date);
        }
    }, [rs]);

    // useEffect(() => {
    //     if (formData.job_roles.length > 0) {
    //         setInitialValues({ ...formData });
    //     }
    // }, [formData]);

    useEffect(() => {
    if (!initialValues || formData.job_roles.length > 0) {
        setInitialValues({ ...formData });
    }
}, [rs]);


    const getInitialFormSchema = () => {
        return Yup.object().shape({
            name: Yup.string().required('Required'),
            start_date: Yup.string().required('Required'),
            end_date: Yup.string().required('Required').test('end-date', 'End date must be after start date', function (value) {
                const { start_date } = this.parent;
                return new Date(value) > new Date(start_date);
            }),
            job_roles: Yup.array().of(Yup.object().shape({
                job_role_id: Yup.string().required('Required'),
                total_hours: Yup.string().required('Required'),
                no_of_resources: Yup.string().required('Required'),
            })).min(1, 'At least one job role is required'),
        });
    };

    const loadJobList = async () => {
        const url = `${process.env.REACT_APP_API_URL}/api/job-role-list`;
        const data = await fetchWrapper.get(url);
        setJobRole(data.rows);
    }

    const addJobRole = () => {
        const newJobRole = {
            job_role_id: '',
            total_hours: '',
            no_of_resources: ''
        };

        const updatedFormData = {
            ...formData,
            job_roles: [...formData.job_roles, newJobRole]
        };

        const updatedSchema = Yup.object().shape({
            ...formSchema.fields,
            job_roles: Yup.array().of(Yup.object().shape({
                job_role_id: Yup.string().required('Required'),
                total_hours: Yup.string().required('Required'),
                no_of_resources: Yup.string().required('Required'),
            })).min(1, 'At least one job role is required'),
        });

        setFormData(updatedFormData);
        setFormSchema(updatedSchema);
    };

    useEffect(() => {
    setInitialValues({ ...formData });
}, [formData]);

    const removeJobRole = (index) => {
        const updatedJobRoles = formData.job_roles.filter((_, i) => i !== index);  // Create a new array without the deleted job role
        setFormData((prevState) => ({
            ...prevState,
            job_roles: updatedJobRoles,
        }));
    };

    const handleDate = (name, e) => {
    if (name === 'start_date') {
        SetStart(e)
        const enddate = endDate
        if (enddate) {
            const start = new Date(e);
            const end = new Date(enddate);
            let totalDays = 0;
            let workingDays = 0;
            for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
                totalDays++;
                if (date.getDay() !== 6 && date.getDay() !== 0) {
                    workingDays++;
                }
            }
            SetWorkedDays(workingDays);
            setWeeks(Math.ceil(totalDays/7))
        }
    }

    if (name === 'end_date') {
        SetEnd(e)
        const startdate = startDate
        if (startdate) {
            const start = new Date(startdate);
            const end = new Date(e);
            let totalDays = 0;
            let workingDays = 0;
            for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
                totalDays++;
                if (date.getDay() !== 6 && date.getDay() !== 0) {
                    workingDays++;
                }
            }
            SetWorkedDays(workingDays);
            setWeeks(Math.ceil(totalDays/7))
        }
    }
};

const handleCal = (name, value) => {
    
    const index = parseInt(name.match(/\d+/)[0]);
    const jobRole = formData.job_roles[index];

    // Update the corresponding field in the job role
    if (name.includes('total_hours')) {
        jobRole.total_hours = value;
    } else if (name.includes('no_of_resources')) {
        jobRole.no_of_resources = value;
    }

    
    const totalHours = parseInt(jobRole.total_hours) * parseInt(jobRole.no_of_resources);

    
    setFullMultiHours(totalHours)
    // console.log(`Total hours for job role ${index}:`, totalHours);
};

const getTotalHours = (index) => {
    const jobRole = formData.job_roles[index];
    // console.log(jobRole)
    if (jobRole && jobRole.total_hours && jobRole.no_of_resources) {
        const totalHours = parseInt(jobRole.total_hours) * parseInt(jobRole.no_of_resources);
        
        return isNaN(totalHours) ? 0 : totalHours;
    }
    return 0;
};

const calculateTotalResources = () => {
    let totalResources = 0;
    formData.job_roles.forEach(jobRole => {
        if(jobRole.no_of_resources){
            // console.log(jobRole.no_of_resources)
            totalResources += parseInt(jobRole.no_of_resources);    
        }
        
    });
    return isNaN(totalResources) ? 0 : totalResources;
};

const calculateTotalHours = () => {
    let FullTotalHours = 0;
    formData.job_roles.forEach(jobRole => {
        if(jobRole.total_hours && jobRole.no_of_resources){
             FullTotalHours += parseInt(jobRole.total_hours) * parseInt(jobRole.no_of_resources);      
        }
       
    });
    return isNaN(FullTotalHours) ? 0 : FullTotalHours;;
};

const FullTotalHours = calculateTotalHours();
const totalResources =calculateTotalResources();
        

     const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
        let data = values;

        const url_id = window.location.href.split('/');
        const id = url_id[url_id.length - 1];

        data.project_id = id


        data.job_roles.forEach(jobRole => {
            const totalHours = (jobRole.total_hours * jobRole.no_of_resources);
            jobRole.full_total_hours = totalHours;
        });
        data.week = Weeks
        data.worked_days = worked
        data.all_total_hours = FullTotalHours
        data.full_total_resources = totalResources
        const url = `${process.env.REACT_APP_API_URL}/api/sow-update`;
        await fetchWrapper.post(url,data);
          meCloseEdit();
        loadList(rs.project_id)

        // console.log(data)
      
    }  

    const resourceList =  Array.from({ length: 20 }, (_, index) => ({
      id: (index + 1).toString(), // ID will be from 1 to 20
      name: (index + 1).toString() // Name will also be from 1 to 20
    }));  

    return (
        <>
            {formSchema && (
                <Form
                    enableReinitialize
                    validationSchema={formSchema}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    <Box w="auto">
                        <InputText name="name" title="SOW Name" />
                    </Box>

                    <HStack w="100%">
                        <Box w="25%">
                            <InputDateField inputProps={{ type: 'date' }} name="start_date" title="Start Date" onChange={(name,e)=> handleDate(name,e)} />
                        </Box>
                        <Box w="25%">
                            <InputDateField inputProps={{ type: 'date' }} name="end_date" title="End Date" onChange={(name,e)=> handleDate(name,e)} />
                        </Box>
                
                        <Box w="25%" className="custom">
                          <label htmlFor="week" className="label">No of Weeks</label>
                          <Input id="week" placeholder={Weeks} disabled
                            _disabled={{ opacity: 1 }}  
                            _placeholder={{ color: "black" }}
                           />
                        </Box>
                        <Box w="25%" className="custom">
						  <label htmlFor="worked_days" className="label">Working Days</label>
						  <Input id="worked_days" placeholder={worked} disabled 
                          _disabled={{ opacity: 1 }}  
                            _placeholder={{ color: "black" }}
                          />
						</Box>
                    </HStack>

                    <Text fontSize="16px" fontWeight="600" borderBottom="1px solid #CCCCCC" pb="15px">Required Resources</Text>

                    {formData.job_roles.map((job, index) => (
                        <HStack key={index} w="100%" mt="20px" alignItems="center">
                            <Box w="60%">
                                {/*<SelectField
                                    name={`job_roles[${index}].job_role_id`}
                                   title={index === 0 ? "Job Role" : ""}
                                    list={job_role}
                                />*/}

                                <SelectFieldSearch title={index === 0 ? "Job Role" : ""} options={job_role} name={`job_roles[${index}].job_role_id`}/>                                                                        


                            </Box>
                            <Box w="15%">
                                <InputText
                                    name={`job_roles[${index}].total_hours`}
                                    title={index === 0 ? "Total Hours Required" : ""}
                                     onChange={(e) => handleCal(`job_roles[${index}].total_hours`,e)}
                                />
                            </Box>
                            <Box w="15%">
                                <SelectField
                                    name={`job_roles[${index}].no_of_resources`}
                                     title={index === 0 ? "No of Resources" : ""}
                                    list={resourceList}
                                     onChange={(e) => handleCal(`job_roles[${index}].no_of_resources`,e)}
                                />
                            </Box>


                             <Box w="10%" className="custom">
                                    <label htmlFor={`total_hours_${index}`} className="label">{index === 0 ? "Total Hours" : ""}</label>
                                    <Input id={`total_hours_${index}`} placeholder={getTotalHours(index)} disabled
                                    _disabled={{ opacity: 1 }}  
                                    _placeholder={{ color: "black" }}
                                     />
                                </Box>

				            {index !== 0 ? 
                                <Box mr="-20px" cursor="pointer" onClick={() => removeJobRole(index)}>
                                    <BsFillTrashFill />
                                </Box>
                           	: ""} 
                        </HStack>
                    ))}

                    <Box borderBottom="1px solid #CCCCCC" pb="15px" color="#3B86FF" ><Link onClick={addJobRole}>Add More</Link></Box>

                    <HStack w="100%" pt="20px" >
                        <Box w="80%">
                            <Text fontSize="16px" fontWeight="600">Total Resources</Text>
                        </Box>
                    
                        <Box w="10%" className="custom">
                          <label htmlFor="full_resources" className="label">No of Resources</label>
                          <Input id="full_resources" placeholder={totalResources} disabled 
                          _disabled={{ opacity: 1 }}  
                            _placeholder={{ color: "black" }}
                          />
                        </Box>

                        <Box w="10%" className="custom">
                          <label htmlFor="full_total_hours" className="label">Total Hours</label>
                          <Input id="full_total_hours" placeholder={FullTotalHours} disabled 
                          _disabled={{ opacity: 1 }}  
                            _placeholder={{ color: "black" }}
                          />
                        </Box>
                    </HStack>

                    <Button py="10px" px="25px" color="#fff" bg="#31367A" fontSize="14px" fontWeight="700" fontFamily="'Open Sans', sans-serif" mb={4} type='submit'>
                        Edit SOW
                    </Button>

                </Form>
            )}
        </>
    )
}

export default Edit;
